<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP TENANT VIEW

type    : view

uses    : header-view
          main-container

route   : /tenants/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-tenant-view" v-if="tenant">
    <header-view
      :title="tenant.tenant_name"
      :previous_page="'/tenants'"
      :allow_edit="true"
      :edit_url="'/tenants/edit/' + uuid" />
    <main-container class="ma-3">
      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Tenant ID</b>
        </v-flex>
        <v-flex md4>
          {{ tenant.uuid }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Tenant Name</b>
        </v-flex>
        <v-flex md4>
          {{ tenant.tenant_name }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Contact Phone</b>
        </v-flex>
        <v-flex md4>
          {{ tenant.contact_phone }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Contact Email</b>
        </v-flex>
        <v-flex md4>
          {{ tenant.contact_email }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Address</b>
        </v-flex>
        <v-flex md4>
          <div>{{ tenant.address }}</div>
          <div>{{ tenant.city }}, {{ tenant.state }} {{ tenant.zip_code }}</div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Country</b>
        </v-flex>
        <v-flex md4>
          {{ tenant.country }}
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Status</b>
        </v-flex>
        <v-flex md4>
          <div>{{ tenant.account_status }}</div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Start Date</b>
        </v-flex>
        <v-flex md4>
          <div>
            {{
              displayDateFormat(
                tenant.subscription_start_date,
                tenant.date_format
              )
            }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>End Date</b>
        </v-flex>
        <v-flex md4>
          <div>
            {{
              displayDateFormat(
                tenant.subscription_end_date,
                tenant.date_format
              )
            }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Subscription Type</b>
        </v-flex>
        <v-flex md4>
          <div>{{ tenant.subscription_type }}</div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md2 class="text-xs-right pr-2">
          <b>Tenant URL Subdomain</b>
        </v-flex>
        <v-flex md4>
          <div>{{ tenant.tenant_url }}</div>
        </v-flex>
      </v-layout>
    </main-container>
  </div>
</template>

<script>
  // components
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  // mixins
  import dateTimeHelperFunctions from '@/mixins/dateTimeHelperFunctions';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppTenantList',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [dateTimeHelperFunctions],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        tenant: null,
        allowTenantView: false,
      };
    },
    created() {
      this.getAllowed();
    },
    methods: {
      async getAllowed() {
        this.allowTenantView = this.$auth.isAllowed('tenants', 'view');

        // make sure we are allowed
        if (this.allowTenantView) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // get tenants
          this.tenant = await Tenants.getTenant(this.uuid, accessToken);
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .tenantList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
